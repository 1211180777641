import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { nanoid } from 'nanoid';
import DefaultLayout from '../layouts/Default';
import DatoSEO from '../components/DatoSEO';
import ImageWithCopyAndTitle from '../components/CMSBlocks/ImageWithCopyAndTitle';
import QuoteBlock from '../components/CMSBlocks/QuoteBlock';
import TitleNav from '../components/CopyBlock/components/TitleNav';
import Hero from '../components/CMSBlocks/Hero';

const EsgPage = ({ data }) => {
	const { title, header, imageWithCopy, quoteWithHeadshot, seoMetaTags } = data.datoCmsEscgPage;

	const location = useLocation();
	let count = 0;

	const showQuoteModule = (iteration) =>
		(imageWithCopy.length <= 2 && iteration === 1) ||
		(imageWithCopy.length > 2 && iteration === 2);

	return (
		<>
			<DatoSEO path={location.pathname} meta={seoMetaTags} />
			<div>
				<Hero
					bgImg={header[0].backgroundImage.url}
					title={title}
					description={header[0].description}
					content={header[0].copyNode.childMdx.body}
					breadcrumbs={{
						parent: {
							title: 'Environment, Social, Community and Governance',
							url: '/esg/',
						},
						currentPage: title,
					}}
				/>
				<TitleNav titles={imageWithCopy.map((el) => el.title)} />
			</div>
			<div>
				{imageWithCopy &&
					imageWithCopy.map((el) => {
						count += 1;
						return (
							<div key={nanoid()}>
								<ImageWithCopyAndTitle
									imgSrc={el.imageWithCopyImage.url}
									alt={el.imageWithCopyImage.alt}
									text={el.textNode.childMdx.body}
									imagePosition={el.imagePosition}
									bgColor={el.backgroundColour}
									title={el.title}
									textQuote={el.textQuote}
								/>
								{showQuoteModule(count) && (
									<section className="bg-secondary-100">
										<div className="container px-3 mx-auto overflow-visible md:px-6">
											<QuoteBlock
												quoteText={quoteWithHeadshot[0].quoteTextNode}
												person={quoteWithHeadshot[0]?.person}
											/>
										</div>
									</section>
								)}
							</div>
						);
					})}
			</div>
		</>
	);
};

export const query = graphql`
	query escgPages($id: String) {
		datoCmsEscgPage(slug: { ne: null }, id: { eq: $id }) {
			header {
				backgroundImage {
					url
					alt
				}
				copyNode {
					childMdx {
						body
					}
				}
				description
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			quoteWithHeadshot {
				quoteTextNode {
					childMdx {
						body
					}
				}
				person {
					associatedCountries
					company
					companyPosition
					companyPositionFullTitle
					country
					personBio
					personName
					personTwitter
					personBioNode {
						childMdx {
							body
						}
					}
					personTwitterHandle
					personImage {
						gatsbyImageData(
							layout: CONSTRAINED
							imgixParams: { auto: "compress" }
							height: 580
							width: 580
						)
						alt
					}
				}
			}
			title
			slug
			imageWithCopy {
				backgroundColour
				imagePosition
				imageWithCopyImage {
					url
					alt
				}
				textNode {
					childMdx {
						body
					}
				}
				title
				textQuote {
					quoteTextNode {
						childMdx {
							body
						}
					}
					author
				}
			}
		}
	}
`;

const WrappedEsgPage = (props) => (
	<DefaultLayout fullWidth>
		<EsgPage {...props} />
	</DefaultLayout>
);

export default WrappedEsgPage;
