import cn from 'classnames';
import React from 'react';
import QuoteProvider from '../../CopyBlock/QuoteProvider';

const Quote = ({ children, author, sideBorder = false }) => (
	<QuoteProvider>
		<div className="py-4 my-5">
			<div
				className={cn(`py-2`, {
					'border-l-4 border-brand-200': sideBorder,
				})}
			>
				<div className="mb-4 text-3xl italic font-normal">{children}</div>
				<p className="mb-4 font-bold last:mb-0 text-brand-200">{author}</p>
			</div>
		</div>
	</QuoteProvider>
);

export default Quote;
