import React from 'react';
import { nanoid } from 'nanoid';
import Container from '../../Container';

const TitleNav = ({ titles }) => (
	<section className="hidden border-b-[1px] border-secondary-400  md:block">
		<Container>
			<div className="flex gap-2 m-auto xxl:gap-10">
				{titles.map((title) => (
					<a className="text-sm font-bold" href={`#${title}`} key={nanoid()}>
						{title}
					</a>
				))}
			</div>
		</Container>
	</section>
);

export default TitleNav;
