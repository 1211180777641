import React from 'react';
import cn from 'classnames';
import CopyBlock from '../../CopyBlock';
import Container from '../../Container';
import Quote from '../Quote';
import BlueRect from '../../../images/vectors/blue-rect.svg';

const ImageWithCopyAndTitle = ({
	imgSrc,
	imagePosition,
	text,
	bgColor,
	title,
	textQuote,
	includeBlueOverlay,
}) => (
	<>
		<section
			className={cn(`last:mb-0 `, {
				'bg-white text-brand-400': bgColor === 'White' || '',
				'bg-secondary-100': bgColor === 'Grey',
				'bg-gradient-to-b from-secondary-100': bgColor === 'Gradient',
			})}
		>
			<Container>
				<div
					className={cn(` md:px-0 lg:pl-8 md:my-12 flex lg:flex-row scroll-mt-24`, {
						'flex-col-reverse': imagePosition === 'Right',
						'flex-col': imagePosition === 'Left',
					})}
					id={title}
				>
					{imagePosition === 'Left' && (
						<div className="relative w-full lg:w-1/2 ">
							<div
								className="w-full my-0 mb-8 overflow-hidden bg-center bg-contain md:mt-8 md:mb-12 h-60 lg:h-96 lg:mb-0 lg:pr-20 mask-oblong-rect"
								style={{ backgroundImage: `url(${imgSrc})` }}
							/>

							{includeBlueOverlay && (
								<img
									src={BlueRect}
									alt="blue rectangle"
									className="absolute top-0 left-0 w-full my-0 mb-8 overflow-hidden xl:left-12 md:mt-8 md:mb-12 h-60 lg:h-96 lg:mb-0 xl:pr-20"
								/>
							)}
						</div>
					)}
					<div
						className={cn(`w-full lg:my-8 md:pr-8 pb-6 lg:w-1/2`, {
							'lg:mr-6': imagePosition === 'Right',
							'lg:ml-6': imagePosition === 'Left',
						})}
					>
						<h2 className="mb-4 text-3xl">{title}</h2>
						<CopyBlock>{text}</CopyBlock>
					</div>
					{imagePosition === 'Right' && (
						<div className="relative w-full lg:w-1/2 ">
							<div
								className="w-full my-0 mb-8 overflow-hidden bg-center bg-contain md:mt-8 md:mb-12 h-60 lg:h-96 lg:bg-cover xl:bg-auto lg:mb-0 lg:pr-20 mask-oblong-rect"
								style={{ backgroundImage: `url(${imgSrc})` }}
							/>

							<img
								src={BlueRect}
								alt="blue rectangle"
								className="absolute top-0 left-0 w-full my-0 mb-8 overflow-hidden xl:left-12 md:mt-8 md:mb-12 h-60 lg:h-96 lg:mb-0 xl:pr-20"
							/>
						</div>
					)}
				</div>
			</Container>
		</section>
		<section>
			{textQuote && textQuote.length > 0 && (
				<div className="container px-3 mx-auto overflow-visible md:px-6">
					<Quote author={textQuote[0].author}>
						<CopyBlock>{textQuote[0].quoteTextNode.childMdx.body}</CopyBlock>
					</Quote>
				</div>
			)}
		</section>
	</>
);

export default ImageWithCopyAndTitle;
